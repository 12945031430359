import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { MoralisProvider } from "react-moralis";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Collection from "./Collection";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MoralisProvider
      serverUrl="https://la41grza14yi.usemoralis.com:2053/server"
      appId="dhdbTbBeaZEAGHEn2J923NgEdzrcVsdjvWWbGkTi"
    >
      <BrowserRouter>
        {/* <Header /> */}
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/collection/:symbol" element={<Collection />} />
        </Routes>
      </BrowserRouter>
    </MoralisProvider>
  </React.StrictMode>
);
