import React from "react";

const NFTCard = ({ data }) => {
  const onClick = () => {
    if (data.buy_link) window.open(data.buy_link);
  };

  return (
    <div className="flex flex-col justify-between px-3 py-2 rounded-md bg-neutral-800">
      <div className="flex items-center flex-1 w-full">
        <img
          src={
            "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/" +
            data.image
          }
          className="w-full min-h-[100px] rounded-md"
        />
      </div>
      <div className="pt-2">
        <div className="text-lg">{data.name}</div>
        <div className="flex flex-col items-center justify-between pt-2">
          <button
            onClick={onClick}
            className="px-1 py-1 border rounded-md disabled:border-0 w-20 flex justify-center align-center gap-2"
            disabled={!data.price}
          >
            <p className="text-purple-600 text-sm ">
              {data.price / 1000000000 || "---"}
            </p>
            <p className="text-sm">SOL</p>
          </button>
          <div className="text-sm">
            Rarity rank:{" "}
            <span className="text-sm text-purple-600">{data.rarity_rank}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTCard;
