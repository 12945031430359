import React from "react";
import { Link } from "react-router-dom";

import snipe from "./assets/img/snipe.png";

import "./NFTDetailMain.css";

const style = {
  display: "-webkit-box",
  // overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
};

const NFTDetailMain = ({ collection }) => {
  if (!collection) return null;
  return (
    <div className="MainContainer">
      <div className="image-container">
        <img
          src={
            "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/" +
            collection.image
          }
          className="image-container-img"
          alt=""
        />
      </div>
      <div className="text-container">
        <div
          className="font-bold text-purple-400  cursor-pointer"
          style={style}
        >
          {collection.name}
        </div>
        <div className="snipe">
          <a href={"/collection/" + collection.symbol} className="">
            <div>
              <img className="snipe-img" src={snipe} alt="" />
            </div>
          </a>
        </div>
        {/* <div className="pt-2 text-sm text-gray-300">
            Floor price: @{collection.floor_price / 1000000000}
          </div>
          <div className="text-sm text-gray-300">
            Volume: @{collection.volume.toLocaleString()}
          </div> */}
        {/* <div className="text-sm text-gray-300">
            Listed: {collection.listed_count}
          </div> */}
      </div>
    </div>
  );
};

export default NFTDetailMain;
